<template>
  <div>
    <div id="landing-container" class="container-fluid" :style="{ backgroundImage: `url('${require('../assets/images/landing-background.jpg')}')` }">
      <div id="landing" class="container container-onepage">
        <div class="row">
          <div class="col-md-12 col">
            <div class="row-aligned-content">
              <img src="../assets/images/buendnis-logo.png">
              <h2>Einfach Schreiben und Lesen lernen</h2>
              <p>Alpha-Bündnis Spandau, das Netzwerk für alle Fragen zur Alphabetisierung und Grundbildung in Spandau.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h3>Was wir tun</h3>
      <p>
        Die Mitglieder des Alpha-Bündnisses Spandau initiieren und unterstützen zielgerichtete Angebote, um die Lese- und Schreibfähigkeit von Erwachsenen zu verbessern.
      </p>

      <h3>Ausgangssituation</h3>
      <p>
        In Spandau gibt es ca. 20.000 funktionale Analphabetinnen und Analphabeten im Alter zwischen 18-65 Jahren. Funktionale Analphabetinnen und Analphabeten sind Personen, die das Lesen und das Schreiben in der Schule gelernt haben, die dennoch nicht ausreichend lesen und schreiben können. Ungefähr 11.600 der Betroffenen in Spandau haben die deutsche Muttersprache, 8.400 haben eine andere Erstsprache.
        Die Betroffenen haben kaum Möglichkeiten, ihre Kenntnisse im Lesen, Schreiben und Rechnen ohne entsprechende Angebote zu verbessern.
      </p>
    </div>

    <div class="container mb-5">
      <h3>Aktuelles</h3>
      <div class="row d-flex align-items-stretch">
        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3">
          <b-card title="Tina lebt buchstäblich! Weltalphatag mit dem Alphabündnis Spandau"
                  :img-src="require('../assets/images/news/2024/20240529_Weltalphatag.jpg')"
                  img-alt="Foto vom Event"
                  img-top
                  tag="article"
                  header="06.09.2024"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Vor einem Jahr standen wir mit Tina und dem ALFA-Mobil auf dem Spandauer Marktplatz. Sie erzählte uns von
              ihrem Sommerabenteuer: ihrem Mitwirken an einer ZDF-Doku über das Leben von Menschen mit funktionalem
              Analphabetismus. Noch war nichts in Sack und Tüten, die Doku noch nicht ausgestrahlt, aber wir waren schon
              beeindruckt und begeistert. Eine von uns, eine Lernerin im Lerncafe, eine Spandauerin, Lerner-Expertin,
              Lern-Botschafterin und alles in allem außerordentliche Persönlichkeit hatte den Mut aufgebracht, vier
              Monate lang vor der Kamera zu stehen und offen über eine Lebenssituation zu sprechen, die die meisten
              Menschen sprachlos macht.
              <br><br>
              An diesem Tag entstand die Idee, ihr und den anderen 12,1 Millionen Erwachsenen in Deutschland, die von
              geringer Literalität betroffen sind, einen Abend zu widmen. Am 6. September war es so weit: Die
              Schirmherrin unseres Spandauer Alphabündnisses Frau Dr. Carola Brückner lud uns in den Gotischen Saal der
              Zitadelle ein, rund 80 Interessierte kamen, trotz immer noch brütender Hitze, dazu, ebenso Paul Barke,
              der Redaktionsleiter aus dem Produktionsteam der Doku, der extra aus Köln angereist war. Gemeinsam
              schauten wir uns Teile von „Buchstäblich leben!“ an, gingen danach ins sehr persönliche Gespräch mit Tina
              Fidan und Paul Barke und konnten noch einmal darauf aufmerksam machen, was für ein Heldentum hinter der
              Alltagskompetenz gering Literalisierter steht und wie viel wir als Gesellschaft noch lernen müssen, um es
              allen Mitmenschen gleich leicht zu machen.<br>
              Wir erfuhren, wie essentiell die Berliner Grundbildungsangebote für Betroffene sind, fürs Lernen und für
              die Gemeinschaft. Wir erfuhren, wie wichtig es ist, gute Freunde zu finden und den Mut zum Nachvornegehen
              zu fassen. Und wir erfuhren, dass „Buchstäblich leben!“ für den Fernsehpreis 2024 nominiert worden ist.
              Das Alphabündnis Spandau drückt die Daumen.
              <br><br>
              Ein interessanter und berührender Abend zum Weltalphabetisierungstag 2024. Danke an alle Beteiligten, an
              Frau Dr. Brückner, an Tina und Paul, an die Mitarbeitenden der Zitadelle, an die Mitglieder des
              Alphabündnisses, die GIZ, an alle Engagierten und an Heimaten für das ausgezeichnete Buffet.
              <br><br>
              Julia Naji
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3">
          <b-card title="Informieren, motivieren und ... konjugieren - mit dem ALFA-Mobil in Spandau"
                  :img-src="require('../assets/images/news/2024/20240529_ALFA-Mobil_Marktplatz.jpg')"
                  img-alt="Foto vom Stand"
                  img-top
                  tag="article"
                  header="29.05.2024"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              „Wie schreibt man „sich ärgern“ in vier verschiedenen Zeitformen – mit allem: ich, du, er, sie, es?“
              Unsere Lernerin Tina Fidan, die Ende 2023 in der ZDF-Doku „Buchstäblich leben!“ eine Hauptrolle spielte,
              forderte Passantinnen und Passanten auf dem Spandauer Marktplatz mit ihrem Sprachquiz ganz schön heraus.
              Was sie damit zeigen wollte: Schreiben und Wörter richtig beugen ist ganz schön schwierig. Das Quiz hatte
              sie im Computerkurs des Lerncafe Spandau am Tag zuvor selbst entworfen und formatiert. „Das muss fertig
              werden, das will ich beim Stand nutzen.“
              <br><br>
              Und genau das tat sie auch, am 29.5.2024 von 10:00 bis 14:00 Uhr, bei der Aktion des ALFA-Mobils und des
              Lerncafes und Alphabündnis Spandau in der Altstadt. Gemeinsam wurde über das Thema geringe Literalität
              informiert, es wurden Grundbildungsangebote in Spandau vorgestellt und einige Augen geöffnet. Denn vielen
              Bürger*innen war nicht bewusst, dass jeder achte deutschsprachige Erwachsene in Deutschland Probleme mit
              dem Lesen und Schreiben hat.
              <br><br>
              nser Alphabündnis war sehr gut vertreten. u.a. waren die VHS vor Ort, die Stadtbibliothek Spandau,
              das Paul-Schneider-Haus, die Wirtschaftsförderung, die Mobile Beratung für Alphabetisierung und
              Grundbildung und das Lerncafe. Sehr gefreut haben wir uns über den Besuch des Stadtrats für Soziales
              Gregor Kempert. Auch ihn hat Tina nicht verschont: „Herr Kempert, wie schreibt man „sich ärgern“ in allen
              Vergangenheitsformen?“.
              <br><br>
              Wir freuen uns auf die nächste Aktion mit dem ALFA-Mobil-Team im kommenden Jahr.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3">
          <b-card title="Jede*r achte Erwachsene! Und ein Gartenzwerg"
                  :img-src="require('../assets/images/news/2023/20230908_IMG_20230906_160917961.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="08.09.2023"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Jede*r achte Erwachsene! Was? Mag keine Bananen? War schon einmal in Budapest? Arbeitet im
              öffentlichen Sektor? Sie werden es wahrscheinlich nicht erraten, hinter dieser Zahl (immerhin 12,1 %) steht
              nämlich ein für viele Menschen erstaunlicher Fakt: Jede*r achte Erwachsene in Deutschland (noch einmal
              anders ausgedrückt: 6,2 Millionen der Deutsch sprechenden Erwachsenen!) ist gering literalisiert, hat also
              Schwierigkeiten mit dem Lesen und Schreiben. In Berlin sind mehr als 456.000 Menschen betroffen, allein
              in Berlin-Spandau etwa 18.000.
              <br><br>
              Anlässlich des <b>Weltalphabetisierungstags</b> macht das <b>Alphabündnis Spandau</b>, ein Netzwerk von
              Organisationen und Einzelpersonen, die sich dem gemeinsamen Ziel verschrieben haben, sich aktiv für das
              Thema Alphabetisierung und Grundbildung in Spandau einzusetzen, dieses Jahr <b>am 7.9.2023 und am
              8.9.2023</b> auf diese große gesellschaftliche Verantwortung aufmerksam – mithilfe eines Gartenzwergs! Denn
              Gartenzwerge veranstalten dieses Jahr berlinweit zwischen dem 6.9. und dem 19.9. einen Aufstand
              <b>(#zwergenaufstandberlin2023)</b>. Achtsamen Berliner*innen werden die auffälligen Figuren auf Social-
              Media-Kanälen, aber auch auf Plätzen und in Fußgängerzonen immer wieder begegnen, sie zum Anhalten,
              zum Nachdenken und zum Fragenstellen inspirieren.
              <br><br>
              Der Spandauer Gartenzwerg wird auf Reisen gehen, sich unter anderem die Stadtbibliothek, die
              Volkshochschule, das Paul-Schneider-Haus, das Lerncafe der GIZ und das Jobcenter anschauen (sie alle sind
              Mitglieder des Alphabündnis), er wird auf Sightseeing-Tour gehen und im Rathaus offiziell von der
              <b>Schirmherrin Frau Dr. Carola Brückner</b>, Stadträtin für Bildung, und <b>Gregor Kempert</b>, Stadtrat für
              Soziales, empfangen. „Der Zwergenaufstand des Alphabündnis erinnert uns daran, dass wir alle eine
              Verantwortung dafür tragen, Barrieren abzubauen und die Chancenbildung für gering literalisierte Menschen
              zu fördern“, so Carola Brückner. „Ich unterstütze als Schirmherrin des Alphabündnis Spandau die Aktion als
              ein lebendiges Zeichen der Solidarität und des Engagements für eine inklusive Gesellschaft.“ Auch Gregor
              Kempert steht hinter der Aktion: „Bildung ist der Schlüssel zur Teilhabe am gesellschaftlichen Leben. Das
              Alphabündnis setzt sich mit großem Engagement dafür ein, dass gering literalisierte Menschen
              Unterstützung und Möglichkeiten erhalten, um ihre Fähigkeiten zu entfalten und selbstbestimmt zu leben.
              Der Zwergenaufstand hat das Anliegen, dafür zu sensibilisieren und dieses Thema verstärkt in die
              Öffentlichkeit zu bringen."
              <br><br>
              Tatsächlich ist das Thema immer noch ein Tabu, Betroffene haben es schwer im Alltag, sie werden belächelt
              oder abgewiesen, entwickeln (in der Schule, im Job, im Freundeskreis) ein großes Geschick, ihre
              Beeinträchtigung zu verstecken und empfinden Teilhabe als nicht selbstverständlich. Es bedarf eines gut
              ausgebauten Systems, das sie respektiert, auffängt und unterstützt, es bedarf dafür ausreichend Ressourcen,
              vor allem aber bedarf es der Normalisierung, denn die oben genannten Zahlen bringen eins klar ans
              Tageslicht: Geringe Literalisierung ist ziemlich normal und überhaupt kein Randphänomen!
              <br><br>
              <b>Am 19.9.2023 ab 11:00 Uhr</b> wird der Spandauer Gartenzwerg gemeinsam mit den anderen Berliner
              Zwergen, mit dem <b>ALFA-Mobil</b>, mit Bündnispartner*innen und politischen Einflussnehmer*innen auf dem
              <b>Alexanderplatz</b> stehen und dafür eintreten, dass das Recht auf Bildung und Teilhabe für alle Menschen in
              Deutschland gleichermaßen verwirklicht wird – wir alle sollten das Gleiche tun.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Reiseziel Spandau: Das „Komm-Café auf Reisen“ zu Besuch beim Alphabündnis Spandau"
                  :img-src="require('../assets/images/news/2023/20230424_Kommcafe_in_Spandau_600x400.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="08.05.2023"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Berlin, 19. April 2023, Sonne, leichte Brise, perfektes Expeditionswetter. So sahen es auch die Lerner-Expert*innen des Grundbildungszentrums, als sie sich mit ihrem Komm-Café auf den Weg ins Lerncafe nach Spandau machten, um unser Alphabündnistreffen sehr willkommen aufzumischen. Denn unverhofft kommt oft: Zu Ehren eines Spandauer Geburtstagskinds hatte sich die Zahl der Teilnehmenden spontan verdoppelt. Beim Komm-Café treffen sich die Lerner*innen und Lernerexpert*innen des GBZ einmal monatlich, um ihre Themen zu besprechen und Neuigkeiten auszutauschen. Üblicherweise in Wilmersdorf, diesmal aber ging man auf Reisen.
              <br/><br/>
              Diskutiert wurde über Sprachniveaus, über individuelle Lern- und Übungsmethoden, über die Verfügbarkeit von Grundbildungsangeboten in Stadt und Land, über erfolgreiche Projekte in Spandau und über Möglichkeiten, diese zu stärken und auszubauen. Die Mitglieder des Alphabündnisses konnten die Lerner*innen zu ihren Wünschen befragen und ihre Perspektive zu aktuellen Plänen und Ideen einholen.
              <br/><br/>
              Viel zu schnell war das Treffen wieder vorbei! Das Alphabündnis Spandau bedankt sich von Herzen für den Besuch des Komm-Cafés, für den mitgebrachten Kuchen, vor allem aber für die uns geschenkte Zeit, den interessanten Input und die Großzügigkeit, mit der die Lerner*innen uns Rede und Antwort standen.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Gesund und bunt – 2. Spandauer Familientag in der Zitadelle"
                  :img-src="require('../assets/images/news/2022/20221102_Spandauer_Familientag_600x400.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="02.11.2022"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Unter dem Motto „Gesund und bunt“ haben das Jobcenter Spandau und das Bezirksamt Spandau am Mittwoch, den 2. November 2022, zum 2. Spandauer Familientag in die Italienischen Höfe in der Zitadelle Spandau geladen. An zahlreichen Ständen konnten sich viele Spandauer Einrichtungen und Angebote vorstellen, die den Familienalltag stärken und ein gesundes Aufwachsen im Blick haben.
              <br/><br/>
              Das Alphabündnis Spandau war zusammen mit seinem Träger GIZ gGmbH und den Projekten „Willkommen-in-Arbeit Büro Spandau“ und den Schulangeboten vertreten. Passend zum Gesundheitsziel „Gesund aufwachsen in Spandau“ konnten die Kinder ein lustiges Bewegungsbingo spielen, bei dem sie wie ein Frosch hüpfen, wie ein Pinguin laufen oder Grimassen schneiden mussten. Das Spiel zog Kleinkinder wie Teenager an. Parallel könnten die Eltern sich über die Angebote des Alphabündnisses und des Trägers informieren.
              <br/><br/>
              Der gut besuchte Familientag stellte eine tolle Möglichkeit dar, Familien zu erreichen und sich noch besser mit den anderen Einrichtungen und Angeboten im Bezirk zu vernetzen. Wir freuen uns schon auf das nächste Jahr!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Der Weltalphatag 2022"
                  :img-src="require('../assets/images/news/2022/20220908_Weltalphatag_600x400.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="08.09.2022"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              In diesem Jahr fanden in Spandau zum Weltalphabetisierungstag am 8. September 2022 verschiedene Aktionen und Veranstaltungen statt:
              <br/><br/>
              Auf dem Marktplatz informierten und sensibilisierten Mitglieder des Alpha-Bündnisses und ein Lerner-Experte zum Thema geringe Literalität sowie zu Beratungs- und Lernangeboten im Bezirk. Außerdem zeigte das Lerncafe Spandau das Spar-Tipp-ABC <a href="https://giz.berlin/news/1030.htm" target="_blank">„schlau.sparen.Spandau“</a>. Die Lerner*innen des Lerncafes hatten sich zu jedem Buchstaben des Alphabets einen Tipp überlegt, wie im Alltag Energie und Geld gespart werden können. Menschen, die Probleme beim Lesen und Schreiben haben, bekommen oft nur prekäre oder gar keine Arbeit und haben dann wenig Geld. Sie sind daher Expert*innen im Sparen und stellen so ihr Wissen allen zur Verfügung. Die Ausstellung ist noch im Fenster des Lerncafes in der Mönchstraße 7 zu sehen.
              <br/><br/>
              Die <a href="https://www.gesbit.de/lebensbegleitendes-lernen/beratung-zu-bildung-beruf-spandau-charlottenburg-wilmersdorf/" target="_blank">Beratung zu Bildung und Beruf</a> bot einen Bewerbungscheck an und unterstützte Interessierte beim Schreiben einer individuellen Bewerbung und eines Lebenslaufs.
              <br/><br/>
              Beim Literaturfestival <a href="https://literatur-inklusive.de/" target="_blank">„Literatur:inklusive“</a> lasen alle Autor*innen in Einfacher Sprache. Außerdem wurden verschiedene Workshops angeboten. Aufgrund des offenen Formats und dem Veranstaltungsort mitten im Kiez wurden die Nachbarschaft und Zielgruppe gut erreicht.
              <br/><br/>
              In der Begegnungsstätte <a href="https://heimaten.giz.berlin/" target="_blank">„Heimaten bei berlinovo“</a> las der syrische Journalist Yahya Alaous aus seiner Kolumne „Mein Leben in Deutschland“ in der Süddeutschen Zeitung über das Ankommen und das Leben nach seiner Flucht, das Erlernen einer neuen Sprache und dessen Herausforderungen. Nach der Lesung gab es bei arabischem Tee und Baklava Gelegenheit für Fragen und Austausch.
              <br/><br/>
              Wir danken allen Bündnismitgliedern, die sich am Weltalphabetisierungstag 2022 beteiligt haben!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Das Alpha-Bündnis Spandau unter einer neuen Schirmherrschaft"
                  :img-src="require('../assets/images/news/2022/20220427_Alphabuendnis_Buendnismitglieder_Schirmherrschaft_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="09.05.2022"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau konnte die Bezirksbürgermeisterin, Frau Dr. Carola Brückner, als neue Schirmherrin gewinnen! In großer Runde trafen sich die Bündnismitglieder am 27. April im Lerncafe Spandau, um die neue Schirmherrschaft gebührend einzuläuten. Die Bündnismitglieder berichteten über ihre Arbeitsfelder und ihre Angebote zur Alphabetisierung und Grundbildung in Spandau.  Das Treffen bot viel zum Raum zum Austausch sowie zur Vernetzung und gab der Schirmherrin einen ersten Eindruck zur Arbeit des Alpha-Bündnisses.
              <br><br>
              Wir freuen uns sehr, mit Frau Dr. Brückner eine engagierte Schirmherrin gewonnen zu haben und heißen sie herzlich willkommen im Alpha-Bündnis Spandau! An dieser Stelle danken wir auch unserem langjährigen Schirmherrn, Swen Schulz, für seine  Unterstützung und sein Interesse sich weiterhin im Bündnis zu engagieren.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Einladung zur kostenlosen Sensibilisierungsschulung mit Gesundheitsschwerpunkt"
                  :img-src="require('../assets/images/news/2021/Alphabuendnis_Schulung_Gesundheit_Bild_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="29.03.2022"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau lädt am 08. April 2022 zu einer kostenlosen Sensibilisierungsschulung mit Gesundheitsschwerpunkt ein! Menschen, die nicht ausreichend lesen und schreiben können, sind auf zusätzliche Unterstützung im Gesundheitswesen angewiesen. Diese Schulung richtet sich daher speziell an Fachkräfte, die im Gesundheitsbereich tätig sind.
              <br><br>
              Sie arbeiten in ärztlichen Praxen, Krankenhäusern, Apotheken, in der Pflege, oder im Ernährungs- und Sportbereich o.ä.? Dann sind Sie herzlich eingeladen teilzunehmen und zu lernen, wie Sie Menschen mit Lese- und Schreibschwierigkeiten erkennen, ansprechen und unterstützen können. <a href="static/20220215_Alphabuendnis_Spandau_Einladung_zur_Sensibilisierungsschulung_mit_Gesundheitsschwerpunkt.pdf" target="_blank">Nähere Informationen finden Sie in der Einladung</a>.
              <br><br>
              <b>Termin:</b> 08.04.2022, 15:00 - 19:00 Uhr<br>
              <b>Veranstaltungsort:</b> Lerncafe Spandau, Mönchstraße 7, 13597 Berlin-Spandau
              <br><br>
              Die Schulung wird vom Grund-Bildungs-Zentrum Berlin durchgeführt und findet unter Einhaltung der Hygienevorschriften und 2G-Regel statt.
              <br><br>
              Um Anmeldung per E-Mail wird an <a href ="mailto:alpha-buendnis@giz.berlin">alpha-buendnis@giz.berlin</a> gebeten.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Lernen Sie das Alpha-Bündnis Spandau kennen!"
                  :img-src="require('../assets/images/news/2021/Logo_Alphabuendnis_Spandau.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="05.01.2022"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau stellt sich vor - in einem brandneuen Reader. Was macht das Alpha-Bündnis? Wer sind die Bündnismitglieder und was bieten diese in Berlin-Spandau an? <a href="static/2021_Alphabuendnis_Spandau_Reader.pdf" target="_blank">All das erfahren Sie hier in unserem Reader</a>, der in möglichst einfacher Sprache verfasst wurde. Haben Sie Lust sich als Institution, Organisation, Unternehmen o.ä. im Alpha-Bündnis Spandau aktiv einzubringen? Schreiben Sie gerne eine E-Mail an <a href="mailto:alpha-buendnis@giz.berlin">alpha-buendnis@giz.berlin</a>. Wir freuen uns, von Ihnen zu hören!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Projektabschluss 2021 der Mobilen Beratung und Begleitung in Spandau"
                  :img-src="require('../assets/images/news/2021/Mobile_Beratung_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="21.12.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Projekt „Mobile Beratung und Begleitung - Lesen · Schreiben · Alltag“ ist zum März 2020 als Kooperationsprojekt der GIZ gGmbH und der Volkshochschule Spandau gestartet, um neue Wege der Zielgruppenerreichung im Bereich Alphabetisierung und Grundbildung zu erproben. Menschen, die Schwierigkeiten mit dem Lesen und Schreiben haben, sind in ihrem Alltag oftmals mit Hürden konfrontiert und in vielen Bereichen von der gesellschaftlichen Teilhabe ausgeschlossen. Auch der Übergang in passende Lernangebote findet noch viel zu wenig statt. An diesen Herausforderungen hat das Projekt angesetzt und ist aufsuchend und mobil an die Orte gegangen, wo die Zielgruppe vermutet wird. Und diese Orte sind - genau wie die Zielgruppe selbst -sehr verschieden: Nachbarschaftstreffs, Beratungsstellen, Schulen, Kitas, Familienzentren, Bibliotheken, die Tafel und vieles mehr waren Anlaufstellen für die Mobile Beratung und Begleitung. Aber auch Alltagsorte wie Arztpraxen, Apotheken, Spätis oder Fahrschulen. Immer war damit die Hoffnung verbunden, Menschen mit geringer Literalität in ihren individuellen Lebenssituationen abzuholen und in ihrer gesellschaftlichen Teilhabe zu stärken. Sei es bei der Begleitung zu einer Behörde, der Unterstützung beim Ausfüllen eines Antrags, bei der Suche nach einem Kitaplatz oder einem passenden Lernangebot. Auch die Themen der Beratungen waren sehr vielfältig. In den fast zwei Jahren konnten viele Menschen in ihren je eigenen Anliegen unterstützt und in Grundbildungsangebote vermittelt werden. Eine Erkenntnis des Projekts ist die Wichtigkeit der persönlichen Ansprache und Zeit für den Aufbau von Beratungsbeziehungen. Zeit braucht es auch, um die Themen Alphabetisierung und Grundbildung durch Netzwerkarbeit und Sensibilisierungen im Bezirk Spandau weiter zu stärken und Kooperationen aufzubauen. Wir hoffen, dass die Projektarbeit im neuen Jahr fortgesetzt werden kann.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Das war der Welt-Alpha-Tag 2021 in Berlin-Spandau!"
                  :img-src="require('../assets/images/news/2021/Weltalphatag_Wanzenfestival_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="09.09.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am 08. September fand in diesem Jahr der Höhepunkt der Spandauer Aktionstage zum Welt-Alpha-Tag am Standort des Wanzen-Festivals statt. Bei strahlendem Sonnenschein und grüner Kulisse wurde den Besucher*innen ein buntes Programm geboten. Es startete mit einer einer Aktion, die sich speziell an Familien gewandt hat. Claudia Parton (<a href="https://schoenegeschichten.de/" target="_blank">Schöne Geschichten.</a>) begleitete Kinder und Eltern bei einem Bilderbuchkino und gab ihnen die Möglichkeit eigene Geschichten anhand von Bildern aus Kinderbüchern zu kreieren. Die Impronauten boten dem Publikum ein Improvisationstheater, das zum Mitmachen einlud.  Informationsmaterialien zu den Themen der Alphabetisierung und Grundbildung sowie zu Angeboten in Spandau wurden bereitgestellt.
              <br><br>
              Wir danken den Initiator*innen des Wanzen-Festivals und allen, die dabei waren!
              <br><br>
              Die Spandauer Aktionstage zum Welt-Alpha-Tag standen insbesondere im Zeichen des Superwahljahrs. Das Alpha-Bündnis Spandau stellte ein Programm zusammen mit Veranstaltungen und Lernangeboten, die einen Schwerpunkt auf die politische Grundbildung legten. Trotz Pandemie konnten vielfältige Formate angeboten werden; darunter Workshops & Kurse, Koch-, Kunst & Theateraktionen sowie andere Aktionen im öffentlichen Raum. Wir danken ganz herzlich allen Unterstützer*innen, die an der Umsetzung der Aktionstage beteiligt waren sowie allen Personen, die an Aktionen teilgenommen haben.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Gemeinsame Aktion mit dem ALFA-Mobil beim Demokratiefestival Spandau"
                  :img-src="require('../assets/images/news/2021/Demokratiekonferenz_alfamobil_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="06.09.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau beteiligte sich mit dem ALFA-Mobil am 04.09.2021 mit einer gemeinsamen Aktion auf dem interaktiven Markt des Demokratiefestivals Spandau. Mit einem Glücksrad und anderen kreativen Methoden wurde auf die Themen der Alphabetisierung und Grundbildung aufmerksam gemacht. Interessierte konnten sich zu Lern- und Beratungsangeboten in Spandau informieren. Auch ein Lerner-Experte war vor Ort, der von seiner eigenen Perspektive erzählen konnte. Es wurde reichlich Informationsmaterial verteilt und sich mit den Besucher*innen des Demokratiefestivals ausgetauscht.
              <br><br>
              Die Aktion fand im Zusammenhang mit den Spandauer Aktionstagen zum Welt-Alpha-Tag 2021 statt.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Podiumsdiskussion „Leicht – verständlich – Politik? Politische Grundbildung und Nachrichten“"
                  :img-src="require('../assets/images/news/2021/Podiumsdiskussion_600x400.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="03.09.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              In Berlin finden im Superwahljahr gleich drei Wahlen und ein Volksentscheid statt. Menschen, die nicht ausreichend lesen und schreiben können, haben Schwierigkeiten sich zu informieren und politisch teilzuhaben. Die Podiumsdiskussion „Leicht – verständlich – Politik? Politische Grundbildung und Nachrichten“ am 02.09.2021 thematisierte in diesem Kontext wie gering literalisierten Menschen der Zugang zu Nachrichten und politischer Bildung erleichtert werden kann. Auf dem Podium saßen Vertreter*innen aus dem Journalismus, der politischen Bildungsarbeit und der Grundbildungslandschaft. Zudem hat ein Lerner-Experte aus seiner Perspektive berichtet.
              <br><br>
              Die Veranstaltung wurde in Zusammenarbeit der Alpha-Bündnissen aus den Bezirken Friedrichshain-Kreuzberg, Neukölln, Spandau, Steglitz-Zehlendorf und Tempelhof-Schöneberg, dem Grund-bildungs-zentrum Berlin sowie der Berliner Landeszentrale für politische Bildung realisiert.
              <br><br>
              Die Podiumsdiskussion ist auf dem <a href="https://www.youtube.com/watch?v=T0E3q_I4TNY&t=3s" target="_blank">YouTube Kanal der Berliner Landeszentrale für politische Bildung</a> abrufbar.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Einladung zu den Spandauer Aktionstagen zum Welt-Alpha-Tag 2021"
                  :img-src="require('../assets/images/news/2021/Logo_Alphabuendnis_Spandau.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="30.08.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau lädt herzlich zu den Spandauer Aktionstagen ein, die im Rahmen des Welt-Alpha-Tags vom 02.09. bis zum 16.09.2021 stattfinden. Das Programm besteht aus vielfältigen Aktionen und Veranstaltungen, die von Lernangeboten zur politischen Grundbildung bis hin zu Improvisationstheater, Kochen oder auch einer Buchstabenjagd reichen. Nähere Informationen sind im <a href="static/20210826_Weltalphabetisierungstag_2021_Berlin-Spandau.pdf">Flyer</a> zu finden. Wir freuen uns Sie begrüßen zu dürfen! <br>
              <br>
              Der Weltalphabetisierungstag wird jährlich seit 1967 am 08. September auf der ganzen Welt begangen. Der Tag wurde ursprünglich von der UNESCO initiiert und soll uns jährlich daran erinnern, dass auch heutzutage viele Menschen weltweit nicht lesen und schreiben können. In Deutschland gibt es beispielsweise 6,2 Millionen Menschen, die nicht ausreichend lesen und schreiben können. Davon leben ungefähr 20.000 Menschen in Berlin-Spandau.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Einladung zur KASA Berlin-Konferenz am 11. August"
                  :img-src="require('../assets/images/news/2021/KASA.svg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="17.07.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Projekt „KASA - Kontrastive Alphabetisierung im Situationsansatz“ der GIZ gGmbH und Bündnismitglied im Alpha-Bündnis Spandau lädt herzlich ein zur Berlin-Konferenz!<br>
              Nach drei Jahren ist es Zeit zurückzuschauen und auszuwerten. Neben Vorträgen der wissenschaftlichen Begleitung des Projektes KASA von Prof. Dr. Karen Schramm und Prof. Dr. Clemens Seyfried sowie des Begleitforschungsprojektes ELIKASA von Prof. Dr. Christine Czinglar werden die Ergebnisse des Projektes vorgestellt.<br>
              <br>
              Die Veranstaltung findet online am 11. August von 10:00 bis 14:30 Uhr statt. Weitere Informationen und das Programm sind hier zu finden. Die Teilnahme ist kostenlos.<br>
              Das KASA-Team freut sich über Anmeldungen möglichst bis zum 10.08.2021 unter kasa.buero@giz.berlin
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Marktplatzaktion in der Altstadt Spandau"
                  :img-src="require('../assets/images/news/2021/marktplatzaktion.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="03.06.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Bei sommerlichen Temperaturen war das Alphabündnis Spandau, mit freundlicher Unterstützung der Vereinigung Wirtschaftshof Spandau e.V., am 03. Juni mit einem Stand auf dem Havelländischen Land- und Bauernmarkt präsent.<br>
              Mit unseren Bündnismitgliedern, der „Mobilen Beratung und Begleitung in Spandau“ sowie dem „Lerncafe“ konnten Besucher*innen des Markts und Passant*innen in der Spandauer Altstadt auf die Themen der Alphabetisierung und Grundbildung aufmerksam gemacht werden. Im Rahmen eines Quiz testeten interessierte Spandauer*innen ihr Wissen zur geringen Literalität in Deutschland. In anregenden Gesprächen konnten Erfahrungen ausgetauscht und wichtige Aufklärungsarbeit geleistet werden.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Neue Bildungsreihe „ABC der Alphabetisierung und Grundbildung“ gestartet!"
                  :img-src="require('../assets/images/news/2021/abc_alphabetisierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="14.04.2021"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alphabündnis Spandau hat sich zum Ziel gesetzt, mehr Aufmerksamkeit für die Themen der Alphabetisierung und Grundbildung zu schaffen. Im Rahmen einer neu gestarteten Bildungsreihe wird auf dem Instagram-Account des Alphabündnisses Spandau (@spandauer.alphabuendnis)  jeden Mittwoch ein relevanter Begriff vorgestellt. Die Bildungsreihe mit dem Titel „ABC der Alphabetisierung und Grundbildung“ möchte aufklären, weiterbilden sowie Chancen und Herausforderungen in der Grundbildungsarbeit aufzeigen. Zudem sollen Möglichkeiten zum Austausch und zur Vernetzung geboten werden. Wir freuen uns, wenn Beiträge kommentiert und geteilt werden und laden alle Interessenten ein, sich mit dem Alphabündnis Spandau auf Instagram (@spandauer.alphabuendnis) zu vernetzen!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Schüler mit 63: ein Analphabet erzählt"
                  :img-src="require('../assets/images/news/2020/reportage_deutsche_welle.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="28.09.2020"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Bei der Deutschen Welle ist eine Reportage über den Alltag eines funktionalen Analphabeten erschienen, in der auch die Alphabündnis-Partner <a href="https://giz.berlin/projects/lerncafe-spandau.htm">Lerncafe Spandau</a> und <a href="https://cuba-med.com/" target="_blank">C.U.B.A. gGmbH</a> gezeigt werden. In dem Beitrag wird der funktionale Analphabet Gerhard Prange vorgestellt und bei seiner Arbeit, in seiner Freizeit und auch beim Lernen an seinem Lernort begleitet. Die Reportage zeichnet dabei ein umfassendes und vielschichtiges Bild eines Menschen, der den Mut gefasst hat, öffentlich über seine Schwierigkeiten zu sprechen, ein Lernangebot aufzusuchen und andere Betroffene zu motivieren, es ihm gleich zu tun.

              Die Reportage ist auf der <a href="https://www.dw.com/de/schüer-mit-63-ein-analphabet-erzählt/av-55062169" target="_blank">Homepage der Deutschen Welle</a> oder auf <a href="https://www.youtube.com/watch?v=71hD2Tslz-0" target="_blank">Youtube</a> abrufbar.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Welt-Alpha-Tag 2020"
                  :img-src="require('../assets/images/news/2020/weltalphatag.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="08.09.2020"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Zum diesjährigen Weltalphabetisierungstag am 08. September lud das Alphabündnis Spandau mit einem kleinen Quiz auf dem Marktplatz in der Spandauer Altstadt zu einem Austausch zum Thema geringe Literalität in Deutschland ein. Es entstanden interessante Gespräche und es konnte auf die verschiedenen Lern- und Beratungsangebote im Bezirk aufmerksam gemacht werden.

              Parallel dazu wurde auch auf dem <a href="https://www.instagram.com/spandauer.alphabuendnis/" target="_blank">Instagram-Account</a> des Bündnisses das Quiz durchgeführt, welches in den Highlights abgespeichert ist und dort weiterhin gemacht werden kann.

              Somit gelang es – trotz Corona – den Weltalphabetisierungstag zu begehen und die Spandauer*innen für das Thema zu sensibilisieren.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Mobile Beratung und Begleitung in Spandau"
                  :img-src="require('../assets/images/news/2020/sven_schaub.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="25.03.2020"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Zum 15. März 2020 startete das Projekt "Mobile Beratung und Begleitung in Spandau - Lesen·Schreiben·Alltag". Das Projekt möchte bis Ende 2021 Menschen darin unterstützen, ein passendes Angebot im Bereich der Alphabetisierung und Grundbildung zu finden. Hierfür werden Menschen mobil und aufsuchend bei ihren individuellen alltäglichen Herausforderungen unterstützt, um so neue Bildungs- und Lernperspektiven zu eröffnen.  Durch die Einbindung in das Alpha-Bündnis Spandau und die Akquise von Kooperationspartner*innen fungiert das Projekt als eine Schnittstelle zwischen Akteur*innen in Spandau und der Zielgruppe gering literalisierter Menschen. Das Kooperationsprojekt zwischen der Gesellschaft für interkulturelles Zusammenleben (GIZ) gGmbH und der Volkshochschule Spandau wird aus Mitteln des Bezirksamts Spandau finanziert.<br>
              Weitere Informationen finden Sie auf der Projektwebsite. Gerne können Sie mir auch eine E-Mail schreiben oder mich anrufen. Ich freue mich, Sie kennenzulernen!<br>
              Für mehr Informationen kontaktieren Sie bitte Herrn Sven Schaub unter svens.schaub [at] giz.berlin.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Aufsuchende Arbeit / Mobile Beratung in der Grundbildung"
                  :img-src="require('../assets/images/news/2020/aufsuchende_arbeit.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="20.03.2020"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
                Am 27.02.2020 fand im Grundbildungszentrum Berlin eine Auftaktveranstaltung zum Thema "Aufsuchende Arbeit / Mobile Bildungsberatung für Alphabetisierung und Grundbildung" mit Susann Kühnapfel statt. Frau Kühnapfel ist Systemischer Coach im LernLaden Neukölln und hat als wissenschaftliche Begleitung das Modellprojekt "Mobile Bildungsberatung" (2006-2008) entwickelt. Frau Kühnapfel stellte das Modellprojekt vor und ging auf Erfolgsfaktoren und Herausforderungen während des Projektverlaufs ein.<br>
                Aufsuchende Arbeit ist ein erfolgversprechendes Instrument, um mehr Menschen mit Grundbildungsbedarf zu erreichen. Wir sind glücklich, zu diesem Thema künftig zusammenzuarbeiten. Es soll eine regelmäßige Fachrunde mit Teilnehmenden aus ganz Berlin stattfinden. Vielen Dank für den interessanten Austausch!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Sensibilisierungsschulung vom Grundbildungszentrum Berlin"
                  :img-src="require('../assets/images/news/2017/finanzierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="04.12.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alphabündnis Spandau lädt zur Sensibilisierungsschulung des Grundbildungszentrums Berlin "Menschen mit Lese- und Schreibschwierigkeiten erkennen, ansprechen, unterstützen" ein! Die Schulung findet am 04.12.2019 in den Schulungsräumen von C.U.B.A. gGmbH (Brunsbütteler Damm 446, 13591 Berlin) statt.<br>

              In der Schulung erhalten Sie grundlegende Informationen zum Thema Alphabetisierung und Grundbildung sowie Handlungsstrategien für den Umgang bei Schriftsprachproblemen.<br>

              Die Einladung mit den genauen Fortbildungsinhalten finden Sie <a href="/static/20191204_sensibilisierung_schulung.pdf">hier</a>.<br>

              Neben der Kurzvorstellung der Angebote des Grund-Bildungs-Zentrums werden Beispiele und Anregungen gegeben. Außerdem ist ein Lerner-Experte anwesend.<br>

              Um Anmeldung per Mail an susanne.angulo [at] giz.berlin wird gebeten.<br>

              Wir freuen uns auf Sie!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Sensibilisierungsschulung vom Grundbildungszentrum Berlin"
                  :img-src="require('../assets/images/news/2019/unternehmerfruehstueck.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="18.11.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Beim Unternehmerfrühstück des Bündismitgliedes <a href="https://www.wirtschaftshof-spandau.de/">Vereinigung Wirtschaftshof Spandau e.V.</a> sprach die Koordinatorin des Alphabündnisses Spandau über Alphabetisierung und Grundbildung. Laut der <a href="https://leo.blogs.uni-hamburg.de/">Leo-Studie 2018</a> sind 62,3% der Menschen mit Lese- und Schreibschwierigkeiten erwerbstätig. Die meisten Arbeitsplätze stellen zumindest basale Anforderungen an das Lesen und Schreiben.<br>

              Beim Frühstück wurde den Unternehmern außerdem das Projekt <a href="https://www.abc-plus.info/">„ABCplus“</a>  der Technischen Akademie Schwäbisch Gmünd vorgestellt, welches Schulungen und Weiterbildungen in Betrieben anbietet, damit Menschen mit geringen Schreib- und Lesekompetenzen die täglichen Herausforderungen der betrieblichen Kommunikation besser bewältigen können.<br>

              Das Alphabündnis Spandau befürwortet die Etablierung von ähnlichen Modellen in Spandau.<br>

              Vielen Dank an die Vereinigung Wirschaftshof Spandau e.V. für die Einladung!<br>
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Internationaler Besuch beim Alphabündnis Spandau"
                  :img-src="require('../assets/images/news/2019/besuch_niederlange.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="22.10.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am Dienstag, den 22.10.2019, empfing das Alphabündnis Spandau eine Delegation aus den Niederlanden, die in Berlin verschiedene Alphabetisierungs- und Grundbildungsprojekte kennenlernen wollte und sich für die Arbeit des Alphabündnisses Spandau und die Angebote der Bündnispartner interessierte. Von den Bündnispartnern stellten sich das <a href="https://giz.berlin/projects/lerncafe-spandau.htm">Lerncafe Spandau</a> (GIZ gGmbH), der <a href="https://www.berlin.de/vhs/volkshochschulen/spandau/kurse/grundbildung-schulabschluesse/">Grundbildungsbereich</a> der Volkshochschule Spandau, das <a href="https://kasa.giz.berlin/">KASA-Projekt</a> der GIZ gGmbH sowie die <a href="https://www.berlin.de/jobcenter-spandau/de-plain/hilfe/alpha-lotsen/">Alpha-Lotsen</a> des Jobcenters Spandau vor.<br>
              Aus den Niederlanden waren vier Delegierte angereist, die verschiedene Alphabetisierungs- und Grundbildungsprojekte im Nachbarland durchführen. Es entstand ein interessanter Austausch über die institutionellen und finanziellen Rahmenbedingungen in beiden Ländern und Ideen zur Erreichbarkeit der Zielgruppe. In den Niederlanden können 2,8 Millionen Menschen nicht ausreichend lesen und schreiben, was 18 % der Bevölkerung zwischen 16-65 Jahren entspricht. In Deutschland sind 6,2 Millionen Menschen oder 12,1 % der Bevölkerung betroffen, wobei hier die Altersgruppen der 18- bis 64-Jährigen betrachtet wurde. Die Zahlen sind daher annähernd vergleichbar.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Fortbildung mit Alphabetisierungspädagoge Peter Hubertus"
                  :img-src="require('../assets/images/news/2019/fortbildung_gluehbirne.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="27.09.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alphabündnis Spandau lädt Sie recht herzlich zu der Fortbildung „Alphabetisierung für Migrantinnen und Migranten/Geflüchtete“ mit Alphabetisierungspädagoge Peter Hubertus am 25.10.2019 ein. Die Fortbildung thematisiert die verschiedenen Lernvoraussetzungen und Lernbedürfnisse unterschiedlicher Teilgruppen (primäre Analphabet*innen, Zweitschriftlerner*innen) und geht auf methodische Fragen ein.
              <br>
              Die Einladung mit den genauen Fortbildungsinhalten finden Sie <a href="/static/20190715_Einladung_Hubertus_AB.pdf">hier</a>. Es sind noch (wenige) Plätze frei.
              <br><br>
              <b>Termin:</b> Freitag, 25.10.2019<br>
              <b>Zeit:</b> 09:00 - 17:00 Uhr (8 UE)<br>
              <b>Ort:</b> Lerncafe Spandau, Mönchstraße 7, 13597 Berlin<br>

              <b>Anmeldung:</b> lerncafe@giz.berlin<br><br>

              Wir freuen uns auf Ihre Anmeldung!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="#weltalphabetisierungstag – Aktion auf dem Spandauer Marktplatz"
                  :img-src="require('../assets/images/news/2019/weltalphabetisierungstag.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="09.09.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Unter dem Motto „Elfchen für Spandau“ fand am Freitag, den 06.09.2019, eine Aktion des Alphabündnisses Spandau anlässlich des Weltalphabetisierungstages 2019 statt. In Kooperation mit der Vereinigung Wirtschaftshof Spandau e.V. wurden auf dem Marktplatz Spandau sogenannte Elfchen – kleine Gedichte in elf Wörtern – präsentiert, welche die Bündnispartner im Vorfeld verfasst haben.
              <br>
              Besucherinnen und Besucher des Havelländischen Bauernmarktes tauschten sich mit den anwesenden Mitgliedern des Alphabündnisses aus und schrieben selbst kurze Gedichte. Als Dank gab es einen frischen Kresse-Topf als Geschenk. Zusätzlich wurde über den <a href="https://www.instagram.com/spandauer.alphabuendnis/">Instagram-Account</a> des Alphabündnisses Spandau dazu aufgerufen, sich an der Aktion zu beteiligen.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Bewerben leicht gemacht!"
                  :img-src="require('../assets/images/news/2019/weltalphatag.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="05.09.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Im Rahmen der diesjährigen Alpha-Woche beteiligt sich die Jobassistenz Spandau mit einer Aktion am 05.09.2019. In der Zeit von 10 bis 17 Uhr bieten wir eine offene Sprechstunde speziell für Menschen mit Lese- und Schreibschwierigkeiten an. Sie bekommen bei uns kostenfreie Beratung zur Jobsuche und Unterstützung beim Erstellen der Bewerbungsunterlagen.
              Sie finden uns am Brunsbütteler Damm 75, 13581 Berlin.
              <br>
              Vereinbaren Sie einen Termin unter: 030/270180950 oder kommen Sie an diesem Tag spontan vorbei!
              <br>
              Ihr Team der Jobassistenz
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Wöchentliche Alpha-Beratung in der VHS Spandau"
                  :img-src="require('../assets/images/news/2019/vhs.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="05.09.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              In der VHS Spandau findet seit Anfang August wöchentlich eine Beratung zu Lesen, Schreiben und Grundbildung statt. In die Beratung können Menschen kommen, die gut Deutsch sprechen, aber Schwierigkeiten beim Lesen und Schreiben haben.<br>
              Mit einigen mündlichen und schriftlichen Aufgaben kann festgestellt werden, welcher Kurs für sie geeignet ist. Und welche Übungen helfen können, das Lesen und Schreiben zu verbessern.<br>
              Außerdem wird zu den aktuellen Angeboten aus dem Grundbildungsbereich beraten, z.B. Englischkurs in langsamem Tempo, Offene Computerwerkstatt oder Spielenachmittag "Spielen einmal anders!".<br>
              Bei Bedarf wird über persönliche Lernziele und über Lernhemmnisse gesprochen.<br>
              Wenn Sie Menschen kennen, die sich mit dem Lesen und Schreiben „schwer tun“, erhalten Sie in der Beratung weitere Informationen, die Sie an Betroffene weitergeben können.<br>
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Perspektivwechsel beim Alphabündnis Spandau"
                  :img-src="require('../assets/images/news/2019/perspektivwechsel_ergebnisse.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="28.01.2019"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              In Spandau leben ca. 20.000 Menschen mit Lese- und Schreibschwierigkeiten. Sie können zwar einzelne Wörter und eventuell auch Sätze lesen und schreiben, nicht jedoch zusammenhängende – auch kürzere und einfache – Texte.
              <br>
              In der Sensibilisierungsschulung vom Grundbildungszentrum Berlin (GBZ), welche am Montag, den 28.01.2019, im Lerncafe Spandau stattfand, erhielten die Teilnehmenden, grundlegende Informationen zum Thema Alphabetisierung und Grundbildung sowie Handlungsstrategien für den Umgang bei Schriftsprachproblemen.
              <br>
              Die Teilnehmenden erarbeiteten zusammen, welche Hürden Betroffenen in ihrem Alltag begegnen und welche Fähigkeiten sie mitbringen und entwickeln, um diese Hürden zu umschiffen. So stellen Ämter, Formulare und Verträge eine besondere Herausforderung dar, aber auch Mobilität, Arztbesuche und der Zugang zu unabhängigen Informationen sind für Betroffene erschwert.
              <br>
              Lerner-Expertin Tina berichtete sehr anschaulich, was es für sie bedeutete, nicht richtig lesen und schreiben zu können. Außerdem wurden Tipps gegeben, wie Betroffene angesprochen werden können und welche Hilfs- und Lernangebote es in Berlin und Spandau gibt.
              <br>
              Die Schulung fand auf Einladung des Alphabündnisses Spandau statt. Das Alphabündnis ist ein Netzwerk an Spandauer Akteuren, welche sich für die Belange von Funktionalen Analphabet*innen einsetzen und die Öffentlichkeit zu diesem Thema sensibilisieren.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="„Unbelehrbar“ Filmvorführung mit Podiumsdiskussion im Paul-Schneider-Haus"
                  :img-src="require('../assets/images/news/2018/20180913_podiumsgaeste.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="13.09.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am Donnerstag, den 13.09.2018, wurde im Rahmen der Weltalphabetisierungswoche der Film „<a href="http://www.front-film.com/">Unbelehrbar</a>“ im <a href="http://paul-schneider-haus.de">Paul-Schneider-Haus</a> gezeigt. Die Koordinatorin des Alpha-Bündnisses Spandau Susanne Angulo (GIZ gGmbH) war in der anschließenden Podiumsdiskussion zu Gast.
              <br>
              Der Film erzählt die Geschichte von Ellen, die im Alter von 40 Jahren beschließt, endlich Lesen und Schreiben zu lernen. Da in der Volkshochschule ihres Heimatortes kein Kurs dazu angeboten wird, zieht sie gegen den Willen ihrer Familie ins nahe Berlin. Dort ist sie das erste Mal völlig auf sich allein gestellt und wird konfrontiert mit der plötzlichen Erfahrung, unabhängig zu sein. Doch Ellen steckt voller Optimismus und hat den Mut zur Konsequenz.
              <br>
              Nach der Filmvorführung fand eine anregende Podiumsdiskussion statt. Podiumsgäste waren die Regisseurin des Films Anke Hentschel, Björn Helbig vom Grundbildungszentrum Berlin, Susanne Angulo, Koordinatorin des Alpha-Bündnisses Spandau und Lerner-Expertin Tina. Die Moderation übernahm die Filmwissenschaftlerin Nadezda Fedorova. Das Publikum zeigte sich sehr interessiert an der Thematik und stellte vor allem Fragen hinsichtlich der Ursachen von (funktionalem) Analphabetismus, aber auch wie man die Zielgruppe am besten mit geeigneten Angeboten erreicht.
              <br>
              Sie kennen jemanden, der betroffen ist? Helfen Sie! Nehmen Sie Kontakt auf! Das Alpha-Bündnis vermittelt weiter.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="„Briefe schreiben für Spandau“ Aktion auf dem Marktplatz Spandau anlässlich des Weltalphabetisierungstages"
                  :img-src="require('../assets/images/news/2018/20180907_gabi.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="07.09.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am Freitag, den 07.09.2018, traf sich das Alpha-Bündnis Spandau auf dem Marktplatz Spandau, um auf zwei alten Schreibmaschinen Briefe für Spandau zu schreiben. Das Kuriosum zog viele neugierige Blicke von Besucher*innen des Havelländer Bauernmarktes auf sich und lud zum Gespräch ein.
              <br>
              Ziel der Aktion war es, auf die Schreib- und Leseschwierigkeiten von ca. 20.000 Spandauerinnen und Spandauer aufmerksam zu machen. Die Betroffenen benötigen Unterstützung bei der Bewältigung alltäglicher Aufgaben wie dem Lesen und Beantworten von Behördenbriefen oder dem Ausfüllen von Formularen. In entsprechenden Lernangebote haben die Betroffenen die Möglichkeit, ihre Kenntnisse im Lesen, Schreiben und Rechnen zu verbessern.
              <br>
              Sie kennen jemanden, der betroffen ist? Helfen Sie! Nehmen Sie Kontakt auf! Das Alpha-Bündnis vermittelt weiter.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="„Briefe schreiben für Spandau“ – Grund-Bildung für alle!"
                  :img-src="require('../assets/images/news/2018/schreibmaschine.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="03.09.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Veranstaltungen und Aktionen anlässlich des Weltalphabetisierungstages
              <br>
              Anlässlich des Weltalphabetisierungstages am 08. September 2018 finden im Bezirk Spandau verschiedene Aktionen statt, um unter dem Motto „Grund-Bildung für alle!“ auf die Situation von Menschen mit Grundbildungsbedarf aufmerksam zu machen und zum Mitreden und Mitdenken einzuladen.
              <br>
              Am Freitag, den 07.09.2018, lädt das Alpha-Bündnis Spandau in Kooperation mit der Vereinigung Wirtschaftshof Spandau e.V. von 12 – 14 Uhr auf dem Marktplatz Spandau zu der Aktion „Briefe schreiben für Spandau“ ein. Das Alpha-Bündnis nimmt Schreibwünsche der Spandauer Bürgerinnen und Bürger entgegen, welche dann auf zwei Schreibmaschinen umgesetzt werden. Die Aktion erinnert an die ägyptische Tradition, nach der auf den Marktplätzen Schreiber für das Volk Briefe geschrieben haben. Ziel der Aktion ist es, über das Thema Funktionaler Analphabetismus und vorhandene Lernangebote im Bezirk zu informieren.
              <br>
              Es finden weitere Aktionen von Bündnispartnern rund um den Weltalphabetisierungstag statt. Den Veranstaltungskalender finden Sie <a href="/static/20180829_weltalphatag_aktionen_spandau.pdf">hier</a>.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Darüber sprechen und Mut machen!"
                  :img-src="require('../assets/images/news/2018/Swen_Schulz_Bundestag.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="03.09.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              <b>Swen Schulz, Schirmherr des Alpha-Bündnisses Spandau, engagiert sich für das Thema Alphabetisierung und Grundbildung</b>
              <br>
              Als Schirmherr des Alpha-Bündnisses Spandau mache ich mich seit 2015 an verschiedenen Stellen für die Themen Alphabetisierung und Grundbildung stark. Antrieb ist für mich, dass funktionale Analphabeten an vielen Stellen von der gesellschaftlichen Teilhabe ausgeschlossen sind. Das müssen wir ändern! Das Thema Grundbildung muss auch zukünftig weiter in den Fokus der Öffentlichkeit gerückt werden. Denn Funktionaler Analphabetismus ist noch immer für zu viele Betroffene ein Tabuthema. Darum ist es von großer Bedeutung, dass die Öffentlichkeit aktiv über dieses Thema aufgeklärt und sensibilisiert wird.
              <br>
              Die engagierte Arbeit der MitarbeiterInnen des Grundbildungszentrums Berlin garantieren dieses Ziel zu einem bedeutenden Teil. Ein weiterer wichtiger Schritt in die Öffentlichkeit ist aber auch die Anschubfinanzierung der Senatsverwaltung für Bildung, Jugend und Familie für die Koordination lokaler Alpha-Bündnisse in den Berliner Bezirken, die ich als Schirmherr des Alpha-Bündnis Spandau sehr begrüße. Die lokale Vernetzung von Ansprechpartnern der Beratungs- und Lernangebote ist besonders wichtig, denn noch immer wissen zu wenig Betroffene von diesen speziellen Angeboten. Ein wichtiger Effekt durch diese Vernetzung wird die zunehmende Professionalisierung in der Beratung sein. Denn je mehr Akteure für das Thema und die Zielgruppe sensibilisiert sind, desto mehr Betroffene können Informationen über Lösungsansätze erhalten.
              <br>
              Des Weiteren ist es ein riesiger Mehrwert, dass nun auch ein <a href="http://alfa-mobil.de">Alfa-Mobil</a> des Bundesverbandes für Alphabetisierung vom Standort Berlin aus für das Thema auf den Straßen der Republik unterwegs ist. Besonders hervorheben möchte ich die engagierte Öffentlichkeitsarbeit von einigen Betroffenen selbst. Sie zeigen Gesicht, informieren in der Öffentlichkeit über ihre ganz eigene Geschichte und machen anderen Mut, sich auf den Weg zur Schrift zu begeben. Diesen Botschaftern gilt unser größter Respekt!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Mit Schultüten ab in die Ferien! Der Kinder- und Familientag"
                  :img-src="require('../assets/images/news/2018/kinder_und_familientag.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="18.06.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Spandau – Am Freitag, den 29. Juni 2018, fand auf dem Spandauer Marktplatz der 12. Kinder- und Familientag statt. Mit vielen Aktionen und Auftritten von und für Kinder wurde der Ferienbeginn diese Woche eingeläutet. Der Tag wurde von der <a href="https://www.wirtschaftshof-spandau.de/">Vereinigung Wirtschaftshof Spandau e.V.</a> organisiert, welche auch Bündnispartnerin des Alpha-Bündnis Spandau ist.
              <br>
              Am Stand des Alpha-Bündnis Spandau war der Andrang groß: Dort wurden mit den Kindern bereits die Schultüten für das kommende Schuljahr gebastelt. Die Tüten konnten mit Stempeln bunt bedruckt und verziert werden. Die Aktion wurde von Teilnehmenden des Weiterbildungskurses <a href="https://giz.berlin/courses/PA.htm">"Pädagogische Assistentin/pädagogischer Assistent"</a> angeleitet.
              <br>
              Ein weiterer Höhepunkt war der Besuch des ALFA-Mobils, welches auf Einladung des Alpha-Bündnisses Spandau dabei war. Das Buchstaben-Glücksrad zog Groß und Klein an und weckte Interesse für das Thema Alphabetisierung und Grundbildung. Hierfür sensibilisiert das <a href="https://alfa-mobil.de/">ALFA-Mobil</a> und informiert über Lese- und Schreibangebote für Erwachsene im Bezirk.
              <br>
              Ein solches Angebot ist das <a href="https://giz.berlin/projects/lerncafe-spandau.htm">Lerncafe Spandau</a>. Denn nicht nur Kinder stellen sich tagtäglich den Herausforderungen des Lesen, Schreibens und Rechnens, sondern auch im Erwachsenenalter lernt man nie aus. Im Lerncafe konnte man bei frisch gebackenen Waffeln und einer Tasse Kaffee mit Lernenden ins Gespräch kommen.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Alpha-Bündnis Spandau am Kinder- und Familientag"
                  :img-src="require('../assets/images/news/2017/finanzierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="18.06.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Das Alpha-Bündnis Spandau wird am Freitag, 29. Juni 2018, am Kinder- und Familientag auf dem Spandauer Marktplatz vertreten sein. Die Mitglieder des Alpha-Bündnisses Spandau initiieren und unterstützen zielgerichtete Angebote, um die Lese- und Schreibfähigkeit von Erwachsenen zu verbessern.
              <br/>
              Nach dem großen Andrang im letzten Jahr wird es auch dieses Jahr eine Schultüten-Bastel-Aktion für Kinder geben. Ein weiterer Höhepunkt stellt der Besuch des ALFA-Mobils dar, das Informationen rund um das Thema "Funktionaler Analphabetismus" liefert und man sich über Lese- und Schreibangebote für Erwachsene informieren kann.
              <br/>
              Nicht nur Kinder stellen sich tagtäglich den Herausforderungen des Lesen, Schreibens und Rechnens, sondern auch im Erwachsenenalter lernt man nie aus. Daher informiert und sensibilisiert das Alpha-Bündnis Spandau zu diesem Thema.
              <br/>
              Gleichzeitig besteht die Möglichkeit, im Lerncafe Spandau in der Mönchstraße 7 in der Altstadt Spandau einen Lernort kennen zu lernen und in gemütlicher Atmosphäre mit Betroffenen ins Gespräch zu kommen.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Sensibilisierungsschulungen im GBZ"
                  :img-src="require('../assets/images/news/2018/GBZ_Schulung.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="11.05.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Gerne machen wir Sie auf die kostenlosen Sensibilisierungsschulungen zu funktionalem Analphabetismus „Erkennen, Ansprechen, Unterstützen" im Grundbildungszentrum Berlin (GBZ) aufmerksam:

              <ul>
                <li>Donnerstag, 24.05.2018, 9:30-13:00 Uhr (Anmeldung bis 11.05.)</li>
                <li>Freitag, 15.06.2018, 13:00-16:30 Uhr (Anmeldung bis 01.06.)</li>
                <li>Dienstag, 03.07.2018, 9:30-13:00 Uhr (Anmeldung bis 18.06.)</li>
                <li>Donnerstag, 20.09.2018, 9:30-13:00 Uhr (Anmeldung bis 09.09.)</li>
              </ul>

              In einem „Crashkurs“ wird die Thematik vorgestellt (Was ist es? Wen betrifft es? Was sind die Ursachen?), die Tarnungen, die Menschen nutzen, um ihre Lese- und Schreibprobleme zu verstecken, erklärt und Tipps zum Erreichen, Ansprechen und Weiterleiten gegeben. So können Sie diese Zielgruppe besser kennenlernen und erhalten dadurch auch Anregungen, wie Sie Ihre Einrichtungen für diese Menschen weiter öffnen können.
              Die Teilnahme ist auf maximal 2 Personen pro Einrichtung je Schulung beschränkt. Genauere Informationen finden Sie dazu auf der Internetseite des <a href="http://grundbildung-berlin.de/schulungen/">GBZ</a>, Anmeldungen für die Schulungen an info@grundbildung-berlin.de
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Buchstäblich fit – Fortbildung für Kursleitende in der Alphabetisierung und Grundbildung im Lerncafe"
                  :img-src="require('../assets/images/news/2018/fortbildung_kursleitende.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="09.05.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Wie lese ich eine Speisekarte? Wie bewege ich mich richtig? Welche Bedeutung hat die Ernährungspyramide? Diesen und weiteren Fragen widmet sich das praxisorientierte Kursleitermaterial „Buchstäblich fit – Besser lesen und schreiben mit den Themen Ernährung und Bewegung“ des Bundeszentrums für Ernährung (BzfE), das von Barbara Klaus und Andrea Staegemann in einem sechsstündigen Workshop im Lerncafe Spandau vorgestellt wurde. Konzipiert für den Einsatz zur Alphabetisierung deutschsprachiger Erwachsener verknüpft das modular aufgebaute Lehrmaterial alltagsrelevante Themen wie Essen, Trinken und Bewegung mit abwechslungsreichen Übungstypologien für alle Alpha-Level.
              Die Teilnehmenden der Fortbildung erfreuten sich dank abwechslungsreicher Methoden einer rundum gelungenen Schulung, die frische Ideen hinsichtlich neuer Lernformen bot. Wir danken Barbara Klaus und Andrea Staegemann für den hochgradig informativen und interessanten Workshop!
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Das BMBF beim Treffen des Alpha-Bündnisses Spandau"
                  :img-src="require('../assets/images/news/2017/finanzierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="04.04.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am 4. April 2018 traf sich das Alpha-Bündnis Spandau wieder zu einer Sitzung im Lerncafe Spandau. Als Gast konnten wir Thomas Bartelt vom Bundesministerium für Bildung und Forschung (BMBF) begrüßen. Herr Bartelt verfolgte die Sitzung sehr aufmerksam und brachte die bundesweite Sicht auf das Thema mit ein.
              Auf der Agenda stand die Vorbesprechung des Treffens der Schirmherre und -frauen aller Berliner Alpha-Bündnisse im Juni im Bundestag. Das Treffen soll dem Austausch unter den Bündnissen dienen und ihre Anliegen in die Politik tragen. Außerdem sollen die nächsten gemeinsamen Schritte besprochen werden. Bei den meisten Alpha-Bündnissen ist der oder die Bezirksbürgermeister*in Schirmherr bzw. Schirmfrau, für das Spandauer Bündnis der Bundestagsabgeordnete Swen Schulz.
              Ein weiterer Punkt auf der Tagesordnung war die Planung des Infostandes beim Spandauer Kinder- und Familientag am 29. Juni 2018. Wie im Vorjahr wird wieder mit den Kindern gebastelt werden und eine Aktion zum Thema funktionaler An-Alphabetismus vorbereitet. Wir freuen uns sehr, dass außerdem das ALFA-Mobil auf Einladung des Alpha-Bündnisses mit einem Stand vertreten sein wird.
              Wir danken Herr Bartelt für seinen Besuch und den Teilnehmenden für die konstruktive und produktive Sitzung. Das nächste Treffen findet am 16. Mai 2018 um 16 Uhr im Lerncafe in der Mönchstraße 7 statt.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Vorstellung des Alpha-Bündnis Spandau im Sozialausschuss der BVV Spandau"
                  :img-src="require('../assets/images/news/2017/finanzierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="14.03.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am 14.3.2018 waren Frau Dr. Marschke, Geschäftsführerin des GIZ gGmbH, und Frau Angulo, Koordinatorin des Alpha-Bündnis Spandau, im Sozialausschuss der Bezirksverordnetenversammlung Spandau eingeladen, um das Alpha-Bündnis Spandau vorzustellen und über funktionalen An-Alphabetismus zu informieren. Die Leiterin der VHS Spandau Frau Altendorf und Claire Zynga vom Grundbildungszentrum Berlin haben sie dabei unterstützt.
              Die Mitglieder des Sozialausschusses zeigten sich sehr interessiert an dem Thema und stellten viele Fragen. Auch wurde überlegt, wie der Sozialausschuss das Alpha-Bündnis Spandau bei seiner Arbeit unterstützen kann. Einige Mitglieder boten an, Kontakte zu weiteren möglichen Bündnispartnern herzustellen.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Neue Koordinatorin für das Alpha-Bündnis Spandau"
                  :img-src="require('../assets/images/news/2018/angulo_lerncafe_alphabuendnis.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="01.03.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Seit dem 1. März 2018 hat Susanne Angulo die Koordination des Alpha-Bündnis Spandau übernommen. Nach ihrer einjährigen Elternzeit freut sich Frau Angulo auf die neuen Aufgaben und die gute Zusammenarbeit im Bezirk und darüber hinaus. Sie hat bereits im Lerncafé gearbeitet und ist mit den Themen der Alphabetisierung und Grundbildung vertraut.
              Wir danken Herrn Özcan Kalkan aus dem ABCami Projekt, dass er die Koordination vertretungsweise übernommen hat.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Erstes Treffen des Alphabündnisses 2018"
                  :img-src="require('../assets/images/news/2018/erstes_treffen.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="11.01.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Am 11. Januar fand sich das Alphabündnis Spandau zum ersten Mal im neuen Jahr in den Räumlichkeiten des Lerncafés zusammen. Gemeinsam ließen wir das vergangene Jahr Revue passieren und richteten den Blick Richtung 2018. Zudem konnte mit Rudi Thurner vom Projekt Alpha.5, ein gemeinsames Projekt der Paritätischen Akademie und der Deutschen Angestellten-Akademie, ein weiterer Bündnispartner gewonnen werden. Wir freuen uns auch 2018 auf eine erfolgreiche und fruchtbare Kooperation! Das nächste Treffen des Spandauer Alphabündnisses findet am 28. Februar um 16 Uhr in den Räumlichkeiten des Lerncafé Spandau statt.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Finanzierung der bezirklichen Alpha-Bündnisse auch 2018/2019 gesichert"
                  :img-src="require('../assets/images/news/2017/finanzierung.png')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="01.01.2018"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Der Senat von Berlin hat gemäß der beschlossenen Richtlinien für die 18. Legislaturperiode Finanzen zur Verfügung gestellt, die eine Unterstützung des Aufbaus der bezirklichen Alpha-Bündnisse ermöglichen. Das Alpha-Bündnis Spandau will damit die weitere Arbeit professionalisieren. Über die genauen Ziele des Spandauer Bündnisses tauschen sich die Bündnispartner bei der nächsten Sitzung aus.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Nächste Sitzung des Alpha-Bündnis Spandau am 11.01.18 um 16 h"
                  :img-src="require('../assets/images/news/2017/meeting-01.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="20.12.2017"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Die Sitzung des Alpha-Bündnisses findet am Donnerstag, dem 11. Januar von 16 bis 17 h im Lerncafe Spandau in der Mönchstrasse 7, 13597 Berlin statt. Für die Tagesordnung vorgesehen ist die Planung der Aktivitäten des Bündnisses für 2018, der aktuelle Stand der Website und die Vorbereitung des Vortrags in der Sitzung des Sozialausschusses der BVV Spandau am 14. Februar.
            </p>
          </b-card>
        </div>

        <div class="col-lg-4 col-md-6 d-flex flex-wrap mt-3" v-if="showOldNews">
          <b-card title="Koordinatorin des Alpha-Bündnisses Spandau geht in den Ruhestand"
                  :img-src="require('../assets/images/news/2017/ruhestand.jpg')"
                  img-alt="Image"
                  img-top
                  tag="article"
                  header="20.12.2017"
                  v-set-class-on-scroll-vertical="'fadeIn'" class="animated">
            <p class="card-text">
              Die Koordinatorin des Alpha-Bündnisses Frau Ursula Diallo-Ruschhaupt geht zum 31.12.17 in den Ruhestand. Wir danken Frau Diallo-Ruschhaupt für ihre Arbeit und das Engagement! Für die Monate Januar und Februar 2018 übernimmt Özcan Kalkan aus dem Projekt ABCami vertretungsweise die Koordination des Bündnisses. Ab dem 1. März 2018 wird unsere Kollegin Frau Susanne Angulo nach ihrer Elternzeit das Alpha-Bündnis koordinieren. Frau Angulo hat bereits im Lerncafe gearbeitet und ist mit dem Thema der Alphabetisierung vertraut.
            </p>
          </b-card>
        </div>
      </div>

      <div class="text-center">
        <b-btn variant="outline-success" v-on:click="showOldNews = true" v-if="!showOldNews" class="mt-3">
          Ältere News anzeigen
        </b-btn>
      </div>
    </div>

    <div class="container">
      <h3>Ziele</h3>
      <p>
        Das Alpha-Bündnis Spandau will mit seiner Arbeit folgende Ziele erreichen:
      </p>
      <ul>
        <li>Sensibilisierung für das Thema An-Alphabetismus bei Erwachsenen</li>
        <li>Bedarfsermittlung in Spandau</li>
        <li>Angebote zur Verbesserung von Grundkompetenzen speziell für funktionale Analphabeten und Analphabetinnen</li>
        <li>Bereitstellung öffentlicher Informationsdokumente sowie eines Kulturangebots in einfacher Sprache</li>
        <li>Entwicklung eines Leitsystems</li>
        <li>Unterstützung von Betroffenen</li>
      </ul>

      <p>
        Unsere Ziele können auch in unserem <a href="static/flyer_buendnis.pdf">Flyer</a> nachgelesen werden.
      </p>
    </div>

    <div class="container">
      <h3>Wer wir sind</h3>
      <p>
        Das Alpha-Bündnis Spandau hat sich auf Initiative der Gesellschaft für interkulturelles Zusammenleben (GIZ) gGmbH im Februar 2015 gegründet. Die Schirmherrschaft haben die Bezirksbürgermeisterin Dr. Carola Brückner sowie Swen Schulz, der das Alpha-Bündnis bereits langjährig unterstützt.
      </p>
      <p>
        Sie können mehr zum Alpha-Bündnis Spandau und den Bündnismitgliedern in unserem <a href="static/2021_Alphabuendnis_Spandau_Reader.pdf" target="_blank">Reader</a> erfahren, der in möglichst einfacher Sprache verfasst wurde oder sich einen ersten Eindruck von den Mitgliedern in folgender Liste verschaffen.
      </p>

      <b-card-group columns>
        <div v-for="item in members" :key="item.title"
          v-set-class-on-scroll-vertical="'zoomIn'" class="animated card">
          <div class="card-body">
            <h4 class="card-title" v-html="item.title"></h4>
            <p class="card-text">{{ item.text }}</p>
          </div>
          <div class="card-overlay">
            <img v-if="item.coverImage" :src="item.coverImage">
            <h3 v-else>{{ item.title }}</h3>
          </div>
        </div>
      </b-card-group>
    </div>

    <div class="container text-center mb-5 mt-4">
      <b-btn variant="outline-success" to="/map">
        <icon name="map" scale="1" class="symbol"></icon> Zur Karte mit Angeboten im Bezirk
      </b-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Alphabuendnis',
  data () {
    return {
      mapCenter: [52.54113457643645, 13.200073242187502],
      mapZoom: 11,
      mapAttribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showOldNews: false,
      members: [
        {
          title: 'Alpha-Bündnis Neukölln',
          text: 'ist ein Projekt zur Zusammenarbeit und Vernetzung von beteiligten Interessengruppen. Inzwischen sind 52 Neuköllner Einrichtungen sowie interessierte Einzelpersonen Bündnispartner*innen.',
          coverImage: require('../assets/images/members/alphabuendnis_neukoelln.png')
        },
        {
          title: 'AWO Kreisverband Spandau e.V.',
          text: 'ist Mitglied im Arbeiterwohlfahrt Landesverband Berlin und seit 1989 ein eigenständiger Kreisverband und als gemeinnütziger Träger der Jugendhilfe anerkannt.',
          coverImage: require('../assets/images/members/awo.png')
        },
        {
          title: 'Grund-Bildungs-Zentrum (GBZ) Berlin',
          text: 'bietet Information, Beratung und Vernetzung für die Alphabetisierung und Grundbildung Erwachsener.',
          coverImage: require('../assets/images/members/GBZ.jpg')
        },
        {
          title: 'Job-Center Spandau',
          text: 'will, dass Jeder bzw. Jede die Informationen über die Aufgaben und wichtigsten Themen im Job-Center Spandau versteht und hat deshalb eine Internet-Seite in einfacher Sprache geschrieben.',
          coverImage: require('../assets/images/members/jobcenter_spandau.png')
        },
        {
          title: 'Schildkröte GmbH',
          text: 'ist eine gemeinnützige Gesellschaft zur Bildung, Beschäftigung, Integration und Vermittlung von Jugendlichen mit besonderem Förderbedarf und langzeitarbeitslosen Erwachsenen.',
          coverImage: require('../assets/images/members/schildkroete.png')
        },
        {
          title: 'Stadtbibliothek Spandau',
          text: 'Wir sind eine Bibliothek. Aber wir sind auch so vieles mehr. Platz der Medienvielfalt, Treffpunkt, Lernort, Raum des Ausprobierens,  analoge und digitale Stütze und Förderstätte.',
          coverImage: require('../assets/images/members/Stadtbibliothek_Spandau.png')
        },
        {
          title: 'Swen Schulz MdB',
          text: 'ist Schirmherr des Lerncafés Spandau, einer Einrichtung des GIZ. Das Lerncafé Spandau ist ein generationenübergreifendes, interkulturelles Kommunikations- und Kompetenzzentrum, das erste seiner Art in Berlin.',
          coverImage: require('../assets/images/members/swen_schulz.png')
        },
        {
          title: 'SPD Fraktion Spandau',
          text: 'unterstützt und befördert in Spandau aktiv Veranstaltungen, Initiativen und Projekte zur Alphabetisierung von Spandauer*innen. Eine Zielsetzung ist, das Thema regional bewusst zu machen, Multiplikator*innen für die Problematik zu sensibilisieren und Betroffene direkt zu erreichen.',
          coverImage: require('../assets/images/members/spd.png')
        },
        {
          title: 'Vereinigung Wirtschaftshof Spandau e.V.',
          text: 'Die Vereinigung Wirtschaftshof Spandau e.V. ist ein moderner Unternehmensverband mit über 320 Mitgliedern, der sich für eine starke Wirtschaft im Bezirk einsetzt.',
          coverImage: require('../assets/images/members/Wirtschaftshof.jpg')
        },
        {
          title: 'VHS Spandau',
          text: 'stellt regelmäßig ein vielfältiges Kursangebot zur Alphabetisierung und Grundbildung bereit und arbeitet hierzu mit unterschiedlichen Einrichtungen und Initiativen zusammen.',
          coverImage: require('../assets/images/members/vhs_spandau.jpg')
        },
        {
          title: 'C.U.B.A. gGmbH',
          text: 'Die C.U.B.A. gGmbH ist in den Arbeitsfeldern Beratung, Umwelt, Bildung und Arbeit aktiv. \n' +
            'Als Kernaufgabe unterstützt C.U.B.A. Menschen professionell bei der Wiedereingliederung in gesellschaftliche Arbeitsprozesse und engagiert sich in der Entwicklungszusammenarbeit. \n \n' +
            'C.U.B.A steht dabei für Consulting • Umwelt • Bildung • Arbeit.',
          coverImage: require('../assets/images/members/cuba_300.jpg')
        },
        {
          title: 'Beratung zu Bildung und Beruf',
          text: 'Die Beratung zu Bildung und Beruf begleitet bei Bildungs- und Berufsentscheidungen und unterstützt bei der Entwicklung beruflicher Perspektiven auf Basis der privaten, beruflichen und individuellen Situation. Die Beratung zu Bildung und Beruf berät zu Kursen und Lernmöglichkeiten zur Verbesserung der Lese- und Schreibkenntnisse und zu allen Fragen rund um Ausbildung, Weiterbildung und passenden Kursangeboten: unabhängig, kostenfrei, mehrsprachig, vor Ort, digital und mobil.',
          coverImage: require('../assets/images/members/BBB_Logo.jpg')
        },
        {
          title: 'Nachbarschafts&shy;zentrum Paul-Schneider-Haus',
          text: 'Das Nachbarschaftszentrum Paul-Schneider-Haus ist ein intergenerationeller und interkultureller Begegnungsort für alle Menschen aus dem Stadtteil. Hier kann jede*r aktiv werden: mit eigenen Ideen oder mit der Teilnahme an offenen Treffs, niedrigschwelligen Beratungs-, Bildungs- und Freizeitangeboten sowie soziokulturellen Veranstaltungen.',
          coverImage: require('../assets/images/members/paul_schneider_haus.png')
        },
        {
          title: 'Die Linke Spandau',
          text: 'Lesen und Schreiben sind der Schlüssel zur Welt. Die Linksfraktion Spandau unterstützt daher Projekte, Initiativen und Aktionen zur Förderung der Alphabetisierung in Spandau.',
          coverImage: require('../assets/images/members/die_linke.png')
        },
        {
          title: 'trias',
          text: 'Das gemeinnützige Unternehmen trias Gesellschaft für Arbeit, Gesundheit und Soziales mbH dient seit der Gründung 1995 der Förderung der Wohlfahrtspflege und beruflichen Wiedereingliederung von sozial benachteiligten Personen,  insbesondere Arbeitslosengeld II Empfängern. Zielsetzung ist, als innovative Bildungs- und Beschäftigungsgesellschaft für die in Berlin stetig wachsenden Dienstleistungsunternehmen durch Beschäftigungs- und Qualifizierungsmaßnahmen eine Vermittlungsplattform zum Arbeitsmarkt zu schaffen. Zu diesem Zweck werden Projekte entwickelt, die flexibel auf die sich verändernden sozialen und beruflichen Anforderungen in der Stadt reagieren. Im Bezirk Spandau engagiert sich die trias gGmbH in Netzwerken im psychosozialen Kontext, im Grundbildungsbereich sowie im Klimaschutz, Artenschutz und im Re-Use Bereich.',
          coverImage: require('../assets/images/members/trias.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .container-onepage {
    height: calc(100vh - 55px);
    display: table;
  }

  .container-onepage .row {
    display: table-row;

    .col {
      display: table-cell;
      vertical-align: middle;
      min-height: 0;
      .row-aligned-content {

        text-align: center;

        img {
          margin-bottom: 20px;
          max-width: 80%;
        }
      }
    }
  }

  #partner-map {
    height: 80vh;
  }

  #landing {
    background-color: rgba(255, 255, 255, 0.8);
  }

  #landing-container {
    background-size: cover;
    margin-bottom: 25px;
  }

  .card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 2;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 3;
    }
    @include media-breakpoint-only(xl) {
      column-count: 4;
    }
  }

  .symbol {
    margin-top: -3px;
    vertical-align: middle;
  }

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);

    display: flex;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    h3, img {
      padding: 5%;
    }

    &:hover, &:focus {
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in;
      transform: scale(1.2);
    }

    transition: opacity 0.4s ease-in-out;
  }
</style>
