import { render, staticRenderFns } from "./Alphabuendnis.vue?vue&type=template&id=62f862fa&scoped=true"
import script from "./Alphabuendnis.vue?vue&type=script&lang=js"
export * from "./Alphabuendnis.vue?vue&type=script&lang=js"
import style0 from "./Alphabuendnis.vue?vue&type=style&index=0&id=62f862fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f862fa",
  null
  
)

export default component.exports